<template>
  <div style="height:90%;" >
    <div class="logo">
      <img src="./logo2.png" alt="" style="  height: 150px;">
    </div>
     <div class="box">
              <div class="searchBox">
                <input type="text" v-model="keyword" placeholder="请写下第一句歌词" class="searchInput" style="padding-left: 10px;">
                <input type="button"  @click="_search" value="生成" class="searchButton">
              </div>
      </div>
      <div class="checkbox">
        <el-tag>歌词字数——最大为150</el-tag>
        <el-input-number v-model="num" @change="handleChange" :min="30" :max="150" label="歌词字数——最大为150"></el-input-number>
        

      </div>
      <div class="wordcard">
        <el-card style="height:100%">
          <div class="lyricwrap">
          <el-tag
           style="width:100%"
            size="large"
            type="warning"
            v-for="(lyric) in lyriclist"
            :key="lyric.key"
          >
          {{lyric}}
        </el-tag>
        </div>
        </el-card>
      </div>
      <div class="foot">
        <div>
          powered by <el-tag onclick="window.location.href= 'http://www.liugehaizaixue.cn';return false">lghzx</el-tag>
        </div>
      </div>
  </div>
   <router-view ></router-view>
</template>

<script>
import { autolyric} from '@/api/autolyric'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      num: 30,
      keyword:'',
      lyriclist:['夏天的风']
    }
  },
  methods:{
    _search(){
      this.$message({
          message: '功能暂未开放',
          type: 'warning'
        });
      return


      let word =this.keyword
      let num=this.num
      if(word==''){
         this.$message({
          message: '请写下第一句歌词',
          type: 'warning'
        });
        return
      }
      const loading = this.$loading({
          lock: true,
          text: '生成歌词中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      let data={
        word,
        num
      }
      // let str = "最美的不是下雨天，是曾与你躲过雨的屋檐 ， 我 们 的 爱 情 ， 就 像 那 ， 一 张 张 泛 黄 的 照 片 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 本 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 ， 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 我 们 的 爱 情 ， 就 像 那 ， 一 本 本 泛 黄 的 书 我 们 的 爱 情 ， 就 像 那";
      // var n = str.split("，");
      // console.log(n)
      // this.lyriclist=n.filter(function (el) {
      //     return el != '';
      //   });
      autolyric(data).then(res=>{
        // this.wordlist=res.data.msg
        this.lyriclist=n.filter(function (el) {
          return el != '';
        });
        loading.close();
      }).catch(err => loading.close())
      

    },
     handleChange(value) {
        console.log(value);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wordcard{
  height: 65%;
  margin: 18px 50px 20px 50px;
}

.wordcard{
   box-shadow: 0 2px 21px rgba(0, 0, 0, 0.23), 0 0 6px rgba(0, 0, 0, 0.04) !important;
  
    
}
.el-card{
  overflow-y:auto;/* 开启滚动显示溢出内容 */
  background-color: #F0FFFF;
  text-align: center;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}
.box{
    margin: 0 auto;
    padding-top: 80px;
    height: 50px;
    width: 100%;
  }
  .searchBox{
    margin: 0 auto;
    width: 60%;
    position: relative;
  }
  .searchInput{
    display: inline-block;
    width: 85%;
    height: 38px;
    border: 1px solid #cccccc;
    float: left;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /* Firefox */
    -webkit-box-sizing:border-box; /* Safari */
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  .searchButton{
    display: inline-block;
    width: 15%;
    height: 38px;
    line-height: 40px;
    float: left;
    background-color: #00a0e9;
    font-size: 16px;
    cursor: pointer;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
    color: #fff;
  }
.checkbox{
  display: flex;
  justify-content: center;
}
.el-tag{
  margin: 5px;
}
.logo{
  position:absolute;
}
.foot{
  display: flex;
   justify-content: center;
  align-items: center;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}
.lyricwrap{

}
</style>
