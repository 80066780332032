
import { createApp } from 'vue'
import {createRouter,createWebHashHistory} from 'vue-router'
import Hello from '@/components/HelloWorld'  //引入根目录下的Hello.vue组件
import Lyric from '@/components/lyric'  //引入根目录下的Hello.vue组件

 

const router = createRouter({
	history:createWebHashHistory(),
	routes:[ {                    //每一个链接都是一个对象
    path: '/yayun',         //链接路径
    name: 'Hello',     //路由名称，
    component: Hello   //对应的组件模板
  },{
    path:'/lyric',
    component:Lyric,
  },{
    path:'/',
    component:Hello,
  }
]
})

export default router