<template>
  <div style="height:90%">
    <div class="logo">
      <img src="./logo.png" alt="" style="  height: 150px;">
    </div>
     <div class="box">
              <div class="searchBox">
                <input type="text" v-model="keyword" placeholder="请输入关键词进行搜索" class="searchInput" style="padding-left: 10px;">
                <input type="button"  @click="_search" value="搜索" class="searchButton">
              </div>
      </div>
      <div class="checkbox">
         <el-radio-group v-model="radio">
          <el-radio-button label="单押"></el-radio-button>
          <el-radio-button label="双押"></el-radio-button>
          <el-radio-button label="三押"></el-radio-button>
      </el-radio-group>
      </div>
      <div class="wordcard">
        <el-card style="height:100%">
          <el-tag
            size="large"
            v-for="(word) in wordlist"
            :key="word.key"
          >
          {{word}}
        </el-tag>
        </el-card>
      </div>
      <div class="foot">
        <div>
          powered by <el-tag onclick="window.location.href= 'http://www.liugehaizaixue.cn';return false">lghzx</el-tag>
        </div>
      </div>
  </div>
   <router-view ></router-view>
</template>

<script>
import { search} from '@/api/search'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      radio:'单押',
      keyword:'',
      wordlist:['你好','世界'],
    }
  },
  methods:{
    _search(){
      let word =this.keyword
      let num
      if(this.radio=='单押'){
        num=1
      }else if(this.radio=='双押'){
        num=2
      }else if(this.radio=='三押'){
        num=3
      }
      if(word==''){
         this.$message({
          message: '请输入关键词',
          type: 'warning'
        });
        return
      }
      let data={
        word,
        num
      }
      search(data).then(res=>{
        // this.wordlist=res.data.msg
        this.wordlist = res.data.msg.filter(function (el) {
          return el != '';
        });

      })

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wordcard{
  height: 65%;
  margin: 20px 50px 20px 50px;
}

.wordcard{
   box-shadow: 0 2px 21px rgba(0, 0, 0, 0.23), 0 0 6px rgba(0, 0, 0, 0.04) !important;
  
    
}
.el-card{
  overflow-y:auto;/* 开启滚动显示溢出内容 */
  background-color: #F0FFFF;
}
.box{
    margin: 0 auto;
    padding-top: 80px;
    height: 50px;
    width: 100%;
  }
  .searchBox{
    margin: 0 auto;
    width: 60%;
    position: relative;
  }
  .searchInput{
    display: inline-block;
    width: 85%;
    height: 38px;
    border: 1px solid #cccccc;
    float: left;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /* Firefox */
    -webkit-box-sizing:border-box; /* Safari */
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  .searchButton{
    display: inline-block;
    width: 15%;
    height: 38px;
    line-height: 40px;
    float: left;
    background-color: #00a0e9;
    font-size: 16px;
    cursor: pointer;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
    color: #fff;
  }
.checkbox{
  display: flex;
  justify-content: center;
}
.el-tag{
  margin: 5px;
}
.logo{
  position:absolute;
}
.foot{
  display: flex;
   justify-content: center;
  align-items: center;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}
</style>
